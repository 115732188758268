'use client';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import './globals.css';

const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
};

interface TemplateProps {
  children: ReactNode;
}

export default function Template({ children }: TemplateProps) {
  return (
    <motion.div
      className="site-wrapper"
      variants={variants}
      initial="hidden"
      animate="enter"
      transition={{
        type: 'linear',
        duration: 1,
      }}
    >
      {children}
    </motion.div>
  );
}
